import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NoDataExistsComponent } from './no-data-exists/no-data-exists.component';
import { ContactsFilterPipe } from '../_utility/contacts.filter';
import { ListCustomerFilterPipe } from '../_utility/list-customer-filter.pipe';
import { ListInvoicePipe } from '../_utility/list-invoice-filter.pipe';
import { ListFilterPipe } from '../_utility/list-product-filter.pipe';
import { OrderBy } from '../_utility/orderBy';
import { RouterModule } from '@angular/router';
import { ScrollbarThemeDirective } from '../_utility/scrollbar-directive.module';
import { ShopDetailsComponent } from './shop-details/shop-details.component';
import { ListExpenseFilterPipe } from '../_utility/list-expense-filter.pipe';
import { ListPaymentFilterPipe } from '../_utility/list-payment-filter.pipe';
import { ListPartyFilter } from '../_utility/list-party-filter.pipe';
import { ListPurchasePipe } from '../_utility/list-purchase-filter.pipe';
import { AddNewProductComponent } from './add-new-product/add-new-product.component';
import { SortPipe } from '../core/pipes/sort.pipe';
import { SortFilterComponent } from './sort-filter/sort-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [
    ListCustomerFilterPipe,
    AddNewProductComponent,
    ListFilterPipe,
    OrderBy,
    ContactsFilterPipe,
    ListInvoicePipe,
    ListPurchasePipe,
    ListExpenseFilterPipe,
    ListPartyFilter,
    NoDataExistsComponent,
    ShopDetailsComponent,
    ScrollbarThemeDirective,
    ListPaymentFilterPipe,
    SortPipe,
    SortFilterComponent
  ],
  exports: [
    ListCustomerFilterPipe,
    ListFilterPipe,
    OrderBy,
    ContactsFilterPipe,
    ListInvoicePipe,
    ListPurchasePipe,
    NoDataExistsComponent,
    ScrollbarThemeDirective,
    ShopDetailsComponent,
    ListExpenseFilterPipe,
    ListPaymentFilterPipe,
    ListPartyFilter,
    AddNewProductComponent,
    SortPipe,
    SortFilterComponent
  ]
})
export class CommonModules { }
