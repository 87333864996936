import { SQLiteDatabaseConfig } from "@ionic-native/sqlite";

class SQLiteObject {
  db: any;

  constructor(db: any) {
    this.db = db;
  };

  executeSql(queryStatement: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(queryStatement, params,
          (tx, result) => {
            resolve(result)
          },
          (error) => reject(error));
      });
    });
  }

  close(cb): Promise<boolean> {
    // empty string means: I do not care what version, desc, size the db is
    var db = (<any>window).openDatabase("Test", "", "TestDB", 1 * 1024);

    function error(tx, err) {
      //console.log(err);
    }

    return new Promise((resolve, reject) => {
      db.transaction(ts => {
        // query all tabels from sqlite_master that we have created and can modify
        var query = "SELECT * FROM sqlite_master WHERE name NOT LIKE 'sqlite\\_%' escape '\\' AND name NOT LIKE '\\_%' escape '\\'";
        var args = [];
        var success = (tx, result) => {
          var rows, i, n, name;

          rows = result.rows;
          n = i = rows.length;

          // invokes cb once it’s called n times
          function after() {
            if (--n < 0) {
              // Change the database version back to empty string
              // (same as when we compear new database creations)
              // db.changeVersion("", "", function () { }, error, cb);
            }
          }

          while (i--) {
            // drop all tabels and calls after() each time
            name = JSON.stringify(rows.item(i).name);
            tx.executeSql('DROP TABLE ' + name, [], after, error);
          }

          // call it just 1 more extra time incase we didn't get any tabels
          after();
          resolve(true);
        };


        ts.executeSql(query, args, success, error);
      });
    });

  }
}


export class SQLiteMock {
  public create(config: SQLiteDatabaseConfig): Promise<SQLiteObject> {
    var db = (<any>window).openDatabase("Test", "", "TestDB", 1 * 1024);

    return new Promise((resolve, reject) => {
      resolve(new SQLiteObject(db));
    });
  }
}