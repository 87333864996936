import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { DEFAULT_ICON } from 'src/app/_constants/icon.constant';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss'],
})
export class ShopDetailsComponent implements OnInit {

  @Input() mainForm: FormGroup;
  @Input() showPassword = true;
  base64Image = DEFAULT_ICON;
  options: BarcodeScannerOptions;
  sizeInBytes = 0;
  constructor(public formBuilder: FormBuilder,
    private camera: Camera,
    private toastService: ToastService,
    private barcodeScanner: BarcodeScanner,) { }

  resetForm() {
    this.mainForm.reset();
  }

  ngOnInit() {
    this.base64Image = this.mainForm.get('logo').value;
  }

  selectImage() {
    const options: CameraOptions = {
      quality: 100,
      allowEdit: false,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
    }
    this.camera.getPicture(options).then(async (imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):

      this.sizeInBytes = (imageData.length * 3 / 4);

      if (this.sizeInBytes > 1000000) {
        await this.toastService.presentToast("The image size exceeds 1MB. Please select a lower resulution image.", "danger");
      } else {
        this.mainForm.get('logo').setValue(this.base64Image);
        this.base64Image = 'data:image/jpeg;base64,' + imageData;
      }


    }, (err) => {
      // Handle error
    });
  }

  scanQr() {
    this.options = {
      prompt: "Scan your barcode ",
      showTorchButton: true,
      formats: 'QR_CODE',
      torchOn: false,
      resultDisplayDuration: 0
    }
    this.barcodeScanner.scan(this.options).then(async (barcodeData) => {
      if (barcodeData.text.startsWith('upi://')) {
        let upiString = barcodeData.text;
        upiString = upiString.split('upi://')[1];
        upiString = upiString.split('pa=')[1];
        upiString = upiString.split('&')[0];
        this.mainForm.get('upi').setValue(upiString);
      } else {
        this.toastService.presentToast('Invalid QR code, please type manually', 'danger');
      }
    }, (err) => {
      //console.log("Error occured : " + err);
    });
  }

}
