import { Pipe, PipeTransform } from '@angular/core';
import { PURCHASE } from '../_models/purchase.model';

let prevFiler: string = '';
let filteredItems: any;
@Pipe({
    name: 'listPurchase',
    pure: false
})
export class ListPurchasePipe implements PipeTransform {
    transform(items: PURCHASE[], filter: string): any {
        if (!items || !filter || prevFiler === filter) {
            return filteredItems && filter && filter.length > 0 ? filteredItems : items;
        }
        prevFiler = filter;
        filteredItems = items.filter(item => {
           return JSON.stringify(item).toUpperCase().includes(filter.toUpperCase())
        });
        return filteredItems;
    }
}