import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IonMenu } from '@ionic/angular';

@Component({
  selector: 'app-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss'],
})
export class SortFilterComponent implements OnChanges {

  @Input() sortOptions: any[];
  @Input() sortBy: string;
  @Input() sortDir: string;
  @Input() menuId: string;
  @Input() sortFilterOpen;
  @Input() maxItems;
  @Input() filterOptions: any[];

  filterForm: FormGroup;

  @Output() applyChanges: EventEmitter<any> = new EventEmitter();

  @ViewChild('sortFilter') ionMenu: IonMenu;
  constructor(private formBuilder: FormBuilder) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortFilterOpen && !changes.sortFilterOpen.isFirstChange()) {
      this.ionMenu.open();

      const formControls = {};

      this.filterOptions.forEach(element => {
        if (element.key === 'quantity') {
          formControls[element.key] = new FormControl(element.value ? element.value : {lower: 0, upper: this.maxItems}, []);
        } else {
          formControls[element.key] = new FormControl(element.value ? element.value : '', []);
        }
      })
      this.filterForm = this.formBuilder.group(formControls);

    }
  }

  emitApplyChanges() {
    if (this.ionMenu.isOpen()) {
      this.filterOptions.forEach(element => {
        element.value = this.filterForm.getRawValue()[element.key];
      })


      this.applyChanges.emit({
        sortBy: this.sortBy,
        sortDir: this.sortDir,
        filters: this.filterOptions
      });
      this.ionMenu.close();
    }
  }
}
