import { Injectable } from '@angular/core';
import { AnimationBuilder, AnimationController, LoadingController, ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  loading: any;
  constructor(public toastController: ToastController,
    private loadingController: LoadingController,
    private animationCtrl: AnimationController) {

    this.loading = this.loadingController.create({
      message: 'Please wait...'
    });
  }

  async presentToast(message: string, color: string, duration: number = 5000) {
    var x = document.getElementById("toast");
    var icon = document.getElementById("img");
    var _message = document.getElementById("desc");
    if(color === 'success') {
      x.style.backgroundColor = '#777';
      icon.innerHTML = "<img src='./assets/tick.png'>";
    } else if(color === 'danger') {
      x.style.backgroundColor = '#777';
      icon.innerHTML = "<img src='./assets/cross.png'>";
    }
    _message.innerText = message;
    x.className = "show";    
    setTimeout(
      function () {
        x.className = x.className.replace("show", "");
      },
      duration);
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            //console.log('Favorite clicked');
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  async startLoading() {
    this.loading = await this.loadingController.create({
      message: 'Please wait...'
    });
    await this.loading.present();
  }

  async stopLoading() {
    await this.loading.dismiss();
  }
}
