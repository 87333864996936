import { FormControl } from '@angular/forms';

export class UnitValidator {
    static checkUnit(control: FormControl): any {
        if (!control.value) {
            return {
                "not a number": true
            };
        }
        if (control.value.length < 0) {
            return ({
                "unit type valid": true
            });
        }
        return null;
    }
}