import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-exists',
  templateUrl: './no-data-exists.component.html',
  styleUrls: ['./no-data-exists.component.scss'],
})
export class NoDataExistsComponent implements OnInit {

  @Input() routeTo: string;
  @Input() objects: any[];
  @Input() buttonName: string;

  constructor() { }

  ngOnInit() {}

}
