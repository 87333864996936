import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PRODUCT } from 'src/app/_models/product.model';

interface state {
    products: any
}

let _state: state = {
    products: null
}

@Injectable({
    providedIn: 'root'
})

export class ProductFacade {

    private productDispatcher = new BehaviorSubject(_state);
    private productFacadeState$ = this.productDispatcher.asObservable();

    products$ = this.productFacadeState$.pipe(map(currentState => currentState.products));

    constructor() {

    }

    setProducts(products: PRODUCT[]) {
        _state = {
            ..._state,
            products: products
        }
        this.productDispatcher.next(_state);
    }
}