import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// plugins
import { SQLite } from '@ionic-native/sqlite/ngx';
import { HttpClientModule } from '@angular/common/http';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { ListCustomerFilterPipe } from './_utility/list-customer-filter.pipe';
import { SQLiteMock } from './_utility/sqlitemock';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { CommonModules } from './common/common.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListPartyFilter } from './_utility/list-party-filter.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(
    ),
    AppRoutingModule,
    HttpClientModule,
    CommonModules,
    NgApexchartsModule

  ],
  providers: [
    StatusBar,
    SplashScreen,
    // SQLite,
    { provide: SQLite, useClass: SQLiteMock },
    AppVersion,
    SQLitePorter,
    Camera,
    BarcodeScanner,
    LaunchReview,
    ListCustomerFilterPipe,
    ListPartyFilter,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SocialSharing, File
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
