import { Pipe, PipeTransform } from '@angular/core';

let prevFiler: string = '';
let filteredItems: any;
@Pipe({
    name: 'listInvoice',
    pure: false
})
export class ListInvoicePipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter || prevFiler === filter) {
            return filteredItems && filter && filter.length > 0 ? filteredItems : items;
        }
        prevFiler = filter;
        filteredItems = items.filter(item => {
           return JSON.stringify(item).toUpperCase().includes(filter.toUpperCase())
        });
        return filteredItems;
    }
}