import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'list-products',
    loadChildren: () => import('./list-products/list-products.module').then( m => m.ListProductsPageModule)
  },
  {
    path: 'add-products',
    loadChildren: () => import('./add-products/add-products.module').then( m => m.AddProductsPageModule)
  },
  {
    path: 'add-products/:id',
    loadChildren: () => import('./add-products/add-products.module').then( m => m.AddProductsPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'customer-list',
    loadChildren: () => import('./customer-list/customer-list.module').then( m => m.CustomerListPageModule)
  },
  {
    path: 'add-customer',
    loadChildren: () => import('./add-customer/add-customer.module').then( m => m.AddCustomerPageModule)
  },
  {
    path: 'add-customer/:id',
    loadChildren: () => import('./add-customer/add-customer.module').then( m => m.AddCustomerPageModule)
  },
  {
    path: 'add-party',
    loadChildren: () => import('./add-party/add-party.module').then( m => m.AddPartyPageModule)
  },
  {
    path: 'add-party/:id',
    loadChildren: () => import('./add-party/add-party.module').then( m => m.AddPartyPageModule)
  },
  {
    path: 'create-invoice',
    loadChildren: () => import('./create-invoice/create-invoice.module').then( m => m.CreateInvoicePageModule)
  },
  {
    path: 'invoice-list',
    loadChildren: () => import('./invoice-list/invoice-list.module').then( m => m.InvoiceListPageModule)
  },
  {
    path: 'invoice-template',
    loadChildren: () => import('./invoice-template/invoice-template.module').then( m => m.InvoiceTemplatePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'order-printer',
    loadChildren: () => import('./order-printer/order-printer.module').then( m => m.OrderPrinterPageModule)
  },
  {
    path: 'query-feedback',
    loadChildren: () => import('./query-feedback/query-feedback.module').then( m => m.QueryFeedbackPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'add-expense',
    loadChildren: () => import('./add-expense/add-expense.module').then( m => m.AddExpensePageModule)
  },
  {
    path: 'add-expense/:id',
    loadChildren: () => import('./add-expense/add-expense.module').then( m => m.AddExpensePageModule)
  },
  {
    path: 'list-expense',
    loadChildren: () => import('./list-expense/list-expense.module').then( m => m.ListExpensePageModule)
  },
  {
    path: 'invoice-repayment',
    loadChildren: () => import('./invoice-repayment/invoice-repayment.module').then( m => m.InvoiceRepaymentPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'list-parties',
    loadChildren: () => import('./list-parties/list-parties.module').then( m => m.ListPartiesPageModule)
  },
  {
    path: 'list-purchase',
    loadChildren: () => import('./list-purchase/list-purchase.module').then( m => m.ListPurchasePageModule)
  },
  {
    path: 'add-purchase',
    loadChildren: () => import('./add-purchase/add-purchase.module').then( m => m.AddPurchasePageModule)
  },
  {
    path: 'purchase-repayment',
    loadChildren: () => import('./purchase-repayment/purchase-repayment.module').then( m => m.PurchaseRepaymentPageModule)
  },
  {
    path: 'purchase-template',
    loadChildren: () => import('./purchase-template/purchase-template.module').then( m => m.PurchaseTemplatePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
