import { Pipe, PipeTransform } from '@angular/core';

let prevFiler: string = '';
let filteredItems: any;
@Pipe({
    name: 'listFilter',
    pure: false
})
export class ListFilterPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter || JSON.stringify(prevFiler) === JSON.stringify(filter)) {
            return filteredItems && filter && filter.length > 0 ? filteredItems : items;
        }

        filteredItems = JSON.parse(JSON.stringify(items));
        prevFiler = JSON.parse(JSON.stringify(filter));
        filter.forEach(element => {
            element.value ? filteredItems = this.filter(element, filteredItems) : filteredItems
        })
       
        return filteredItems;
    }

    private filter(filterOption, filteredItems) {
        switch(filterOption.type) {
            case 'slider': return this.silder(filterOption, filteredItems);
            case 'text': return this.text(filterOption, filteredItems);
        }
    }

    private silder(filterOption, filteredItems) {
        return filteredItems.filter(item => {
            return item[filterOption.key] >= filterOption.value.lower && item[filterOption.key] <= filterOption.value.upper
        })
    }

    private text(filterOption, filteredItems) {
        return filteredItems.filter(item => {
            return item[filterOption.key].toUpperCase().indexOf(filterOption.value.toUpperCase()) !== -1
        })
    }
}