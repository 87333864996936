import { Pipe, PipeTransform } from '@angular/core';

let prevFiler: string = '';
let filteredItems: any;
@Pipe({
    name: 'listPaymentFilter',
    pure: false
})
export class ListPaymentFilterPipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter || prevFiler === filter) {
            return filteredItems && filter && filter.length > 0 ? filteredItems : items;
        }
        prevFiler = filter;
        filteredItems = items.filter(item => item.customer_name.toUpperCase().indexOf(filter.toUpperCase()) !== -1);
        return filteredItems;
    }
}