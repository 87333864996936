import { Directive, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';
@Directive({
    selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
    constructor(el: ElementRef, private platform: Platform) {
        const stylesheet = `
      ::-webkit-scrollbar {
      width: 7px;
      }
      ::-webkit-scrollbar-track {
      background: #fff;
      }
      ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: linear-gradient(var(--ion-color-light-tint), var(--ion-color-light));
      border: 4px solid #e1e1e1;
      }
      ::-webkit-scrollbar-thumb:hover {
      }
    `;

        const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

        if (!this.platform.is('mobile')) {
            if (styleElmt) {
                styleElmt.append(stylesheet);
            } else {
                const barStyle = document.createElement('style');
                barStyle.append(stylesheet);
                el.nativeElement.shadowRoot.appendChild(barStyle);
            }
        }

    }
}