import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BarcodeScannerOptions, BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AlertController, ModalController } from '@ionic/angular';
import { UnitValidator } from 'src/app/add-products/unit.validator';
import { DbService } from 'src/app/services/db.service';
import { ToastService } from 'src/app/services/toast.service';
import { RUPEE_SYMBOL } from 'src/app/_constants/constants';
import { ITEM_SALES } from 'src/app/_models/item-sales.model';
import { PRODUCT } from 'src/app/_models/product.model';

@Component({
  selector: 'app-add-new-product',
  templateUrl: './add-new-product.component.html',
  styleUrls: ['./add-new-product.component.scss'],
})
export class AddNewProductComponent implements OnInit {

  @Input() product: PRODUCT;
  @Input() addInInvoice: boolean;
  mainForm: FormGroup;
  products: PRODUCT[] = [];
  RUPEE_SYMBOL = RUPEE_SYMBOL;
  
  barcodes: string[] = [];
  options: BarcodeScannerOptions;
  selectedProductId: string;
  _unitType: string[] = ['Weight', 'Length', 'Volume', 'Piece'];
  _unit = new Map([
    ["Weight", new Array('gram', 'kgs', 'milligram')],
    ["Length", new Array('meter', 'km', 'millimeter', 'feet')],
    ["Volume", new Array('liter', 'ml')],
    ["Piece", new Array('unit', 'box')]
  ]);
  private prevQuantity: number = 0;

  constructor(private db: DbService,
    private router: Router,
    public formBuilder: FormBuilder,
    private barcodeScanner: BarcodeScanner,
    private toastService: ToastService,
    private activatedRouter: ActivatedRoute,
    private alertController: AlertController,
    private modalController: ModalController
  ) {
    this.selectedProductId = this.activatedRouter.snapshot.paramMap.get('id');
  }

  scan() {
    this.options = {
      prompt: "Scan your barcode ",
      showTorchButton: true
    }
    this.barcodeScanner.scan(this.options).then((barcodeData) => {
      this.barcodes.push(barcodeData.text);
    }, (err) => {
      this.toastService.presentToast('Error occured while scanning the barcode', 'danger');
    });
  }

  removeBarcode(barcode: string) {
    this.barcodes.splice(this.barcodes.indexOf(barcode), 1);
    this.toastService.presentToast('Barcode removed from list', 'success');
  }

  async addProduct() {
    this.mainForm.get('barcodes').setValue(this.barcodes.join());
    this.modalController.dismiss(this.mainForm.getRawValue());
  }

  async quantityAlert() {
    const alert = await this.alertController.create({
      header: 'Enter Quantity',
      inputs: [
        {
          name: 'quantity',
          type: 'number',
          placeholder: 'Enter quantity',
          value: this.mainForm.get('quantity').value,
          handler: (response) => {
            ////console.log(response);
          }
        }
      ],
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Save',
          handler: async (response) => {
            this.mainForm.get('quantity').setValue(response.quantity);
          }
        }
      ]
    });
    await alert.present();
  }

  getItemSales(productId: number) {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    const itemSales: ITEM_SALES = {
      productId: productId,
      quantity: Number(this.mainForm.get('quantity').value) - this.prevQuantity,
      date: date.getTime(),
      action: 'manual',
      invoice_id: null,
      remaining_stock: Number(this.mainForm.get('quantity').value),
      uuid: localStorage.getItem('uuid')
    };
    return itemSales;
  }

  async confirmDialog() {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to delete this record?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Yes',
          handler: async (response) => {
            await this.db.deleteProduct(Number(this.selectedProductId)).then(() => {
              this.toastService.presentToast('Record deleted successfully.', 'success');
              this.router.navigate(['/', 'list-products']);
            })
          }
        }
      ]
    });
    await alert.present();
  }

  async resetForm() {
    this.mainForm.reset();
    this.barcodes = [];
    this.toastService.presentToast('Form reset', 'success');
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    let product: PRODUCT = {
      name: null,
      barcodes: null,
      price: null,
      tax: 0,
      unit: 'unit',
      unitType: 'Piece',
      quantity: 0,
      price_including_tax: true,
      cost_price: null,
      avg_cost_price: null,
      uuid: localStorage.getItem('uuid')
    }
    if (this.product) {
      product = this.products.filter(element => element.id === Number(this.product))[0];
      this.prevQuantity = JSON.parse(JSON.stringify(product.quantity));
      this.barcodes = product.barcodes.length > 5 ? product.barcodes.split(',') : [];
      this.mainForm = this.formBuilder.group({
        name: [product.name],
        price: [product.price],
        tax: [product.tax],
        cost_price: [product.cost_price],
        avg_cost_price: [product.avg_cost_price],
        barcodes: [product.barcodes],
        unitType: [product.unitType, [UnitValidator.checkUnit]],
        unit: [product.unit, [UnitValidator.checkUnit]],
        quantity: [product.quantity],
        price_including_tax: [JSON.parse(product.price_including_tax + '')],
        id: [product.id],
        uuid: [localStorage.getItem('uuid')]
      });
    } else {
      this.prevQuantity = 0;
      this.mainForm = this.formBuilder.group({
        name: [product.name],
        price: [product.price],
        cost_price: [product.cost_price],
        avg_cost_price: [product.avg_cost_price],
        tax: [product.tax],
        barcodes: [product.barcodes],
        unitType: [product.unitType, [UnitValidator.checkUnit]],
        unit: [product.unit, [UnitValidator.checkUnit]],
        quantity: [1],
        price_including_tax: [true],
        uuid: [localStorage.getItem('uuid')]
      });
    }
  }

  getFinalRate() {
    if (this.mainForm.get('price_including_tax').value === true) {
      return Number(this.mainForm.get('price').value).toFixed(2);
    } else {
      return Number((Number(this.mainForm.get('price').value) + Number(this.mainForm.get('price').value) * Number(this.mainForm.get('tax').value) / 100).toFixed(2));
    }
  }

  changedPrice() {
    this.mainForm.get('price').setValue(Number(this.mainForm.get('price').value).toFixed(2));
  }

  changedCostPrice() {
    this.mainForm.get('cost_price').setValue(Number(this.mainForm.get('cost_price').value).toFixed(2));
    this.mainForm.get('avg_cost_price').setValue(Number(this.mainForm.get('cost_price').value).toFixed(2));
  }

  decreaseQuantity() {
    if (Number(this.mainForm.get('quantity').value) === 0) {
      return;
    }
    this.mainForm.get('quantity').setValue(Number(this.mainForm.get('quantity').value) - 1)
  }

  increaseQuantity() {
    this.mainForm.get('quantity').setValue(Number(this.mainForm.get('quantity').value) + 1)
  }

  dismiss() {
    this.modalController.dismiss();
  }



}
